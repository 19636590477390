<!--Common wrapper for dynamic tabs in order details page. For info about methods refer dashboardWrapper as they are the same -->
<template>
  <div
    class="card u-min-height-100 u-flex-align-items-center u-flex-justify-content-center"
  >
    <div
      v-if="!isCustomActionStateVisible && !showRecCustomComponent && activeTab"
      class="u-display-flex u-flex-justify-content-flex-start"
    >
      <splitTabs
        :active-tab="activeTab"
        :is-tabs-list="true"
        :tabs-list="tabsList"
        @tabClicked="onTabClick"
      />
    </div>
    <dashboard-inflator
      class="u-position-relative tabs-component-wrapper"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :show-header="false"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import { eventBus } from '@/utils/services/eventBus';
import { cloneDeep } from 'lodash';

export default {
  name: 'TabsComponentWrapper',
  mixins: [dashboardMixin],
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    }
  },
  data() {
    const pageInfo = {
      name: this.tabMetadata.page
    };
    const context = this;
    return {
      context,
      pageInfo,
      initRequestParams: {
        global: {
          ...pageInfo,
          ':page': pageInfo.name,
          ':entityType': '#ALLOVER_AGGREGATE'
        }
      },
      showPlotRowsChip: false,
      rowSelections: [],
      activeComponent: '',
      gridOptions: {
        context: { componentParent: this },
        domLayout: 'normal',
        onRowDataChanged: this.rowDataChanged
      },
      columnKeyMapping: {},
      widgetMetaData: [],
      activeTab:
        this.entityConfig?.tabConfig?.[this.tabMetadata.name]?.switch
          ?.tabsList[0],
      // custom-recommendations state variables
      recFilter: '',
      recCustomComponent:
        this.entityConfig.tabConfig[this.tabMetadata.name].recCustomComponent,
      showRecCustomComponent: false,
      preferNoRecFilterList:
        this.entityConfig.tabConfig[this.tabMetadata.name]
          .preferNoRecFilterList || [],
      selectedRecommendation: {},
      isCustomActionStateVisible: false
    };
  },
  computed: {
    headerOptions() {
      return this.entityConfig.tabConfig[this.tabMetadata.name].headerOptions(
        this,
        this.tabMetadata.widgets[0]
      );
    },
    tabsList() {
      return this.activeTab
        ? this.entityConfig.tabConfig[this.tabMetadata.name].switch.tabsList
        : [];
    }
  },
  watch: {
    dateFilter: {
      handler(newVal) {
        this.initRequestParams.global = {
          ...this.initRequestParams.global,
          ...newVal
        };
        const initRequestParams = this.entityConfig.tabConfig[
          this.tabMetadata.name
        ].widgetConfig(this.initRequestParams, this.entityId);
        this.updateEntireRequestParams(initRequestParams);
      },
      immediate: true,
      deep: true
    },
    requestParams(newVal) {}
  },
  created() {
    const initRequestParams = this.entityConfig.tabConfig[
      this.tabMetadata.name
    ].widgetConfig(this.initRequestParams, this.entityId);
    if (this?.entityConfig?.tabConfig?.[this.tabMetadata.name]?.components) {
      for (const [key, value] of Object.entries(
        this.entityConfig.tabConfig[this.tabMetadata.name].components
      )) {
        Vue.component(key, value);
      }
    }
    this.updateEntireRequestParams(initRequestParams);
  },
  methods: {
    handleSelectionChange(selections, manuallyDeselectGrid = false) {
      this.rowSelections = selections.map((sel) => ({ data: sel.data })) || [];
      if (this.rowSelections.length <= 0) {
        this.isCustomActionStateVisible = false;
      }
      if (manuallyDeselectGrid === true) {
        // this scenario happens when user clicks on x mark in acitons bar, we send true manually
        this.gridOptions?.api?.deselectAll();
      }
    },
    openSnackbar(content) {
      this.$snackbar.open({
        message: content,
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    switchFunctionToCall() {},
    openActivityLog(data) {
      const payload =
        this.entityConfig.tabConfig[this.tabMetadata.name].getActivityData(
          data
        );
      eventBus.$emit('entityOpenActivityLog', payload);
    },
    switchBlueBar(name) {
      eventBus.$emit('entityBlueBar', name);
    },
    onTabClick(tab) {
      this.activeTab = tab;
      const page = this.entityConfig.tabConfig[this.tabMetadata.name];
      const tableRequestParams = cloneDeep(
        this.getWidgetRequestParams(page.widgetNames.table)
      );
      tableRequestParams[':dimensionNameValueList'] = tableRequestParams[
        ':dimensionNameValueList'
      ].map((item) =>
        item.dimensionName === page.switch[tab].dimensionName
          ? page.switch[tab]
          : item
      );
      this.updateWidgetRequestParams(
        tableRequestParams,
        page.widgetNames.table
      );
    },
    rowDataChanged(event) {
      this.handleSelectionChange([]);
      if (this.recFilter) {
        this.gridOptions.api.selectAll();
      }
    },
    recommendationFilter(val, label) {
      const page = this.entityConfig.tabConfig[this.tabMetadata.name];
      const tableRequestParams = cloneDeep(
        this.getWidgetRequestParams(page.widgetNames.table)
      );
      if (val) {
        const [value] = Object.values(val);
        tableRequestParams[':dimensionNameValueList'].push({
          dimensionName: page.recommendationKey,
          dimensionValue: value,
          operator: 'ILIKE'
        });
        this.recFilter = { value, label };
        if (this.preferNoRecFilterList.includes(value)) {
          this.toggleRecCustomComponent(true);
          return;
        }
        this.updateColumnMapping();
      } else {
        tableRequestParams[':dimensionNameValueList'] = tableRequestParams[
          ':dimensionNameValueList'
        ].filter((item) => item.dimensionName !== page.recommendationKey);
        this.recFilter = '';
        this.updateColumnMapping();
      }
      this.updateWidgetRequestParams(
        tableRequestParams,
        page.widgetNames.table
      );
    },
    metadataUpdated(data) {
      this.widgetMetaData = cloneDeep(data);
    },
    updateColumnMapping() {
      const page = this.entityConfig.tabConfig[this.tabMetadata.name];
      const table = cloneDeep(
        this.widgetMetaData?.find((e) => e?.name === page.widgetNames?.table)
      );
      if (table) {
        const columnKeyMapping = {};
        const metadataToReplaceKey = page.metadataToReplaceKey;
        const { alias: metricAlias } = table.metrics[metadataToReplaceKey];
        const metadataToReplace = cloneDeep(
          table.metrics[metadataToReplaceKey].metadata
        );
        if (this.recFilter) {
          const metadataToReplaceWith = page.metadataToReplaceWith(
            this.recFilter.value,
            cloneDeep(metadataToReplace)
          );
          columnKeyMapping[metricAlias] = metadataToReplaceWith;
          this.columnKeyMapping = columnKeyMapping;
        } else {
          columnKeyMapping[metricAlias] = metadataToReplace;
          this.columnKeyMapping = columnKeyMapping;
        }
      }
    },
    toggleRecCustomComponent(val) {
      this.showRecCustomComponent = val;
    },
    handleRecommendation(val) {
      this.selectedRecommendation = val;
    },
    // event listener to capture the action state in actions bar
    toggleCustomActionState(val) {
      this.isCustomActionStateVisible = val;
    }
  }
};
</script>
<style lang="css">
.custom-component {
  display: flex;
  flex-direction: column;
}
.tabs-component-wrapper {
  min-height: 70vh;
}
.tabs-component-wrapper .dashboard-service-table-wrapper,
.tabs-component-wrapper .recommendations-container {
  margin: 0;
  padding-bottom: 0;
}
.tabs-component-wrapper
  .dashboard-service-table-wrapper
  .custom_widget_container.card,
.tabs-component-wrapper
  .recommendations-container
  .custom_widget_container.card {
  border-top: none;
  box-shadow: none;
}
.tabs-component-wrapper .dashboard-service-table-wrapper .header-container-cwc,
.tabs-component-wrapper .recommendations-container .header-container-cwc {
  padding: 0;
}
.tabs-component-wrapper .dashboard-service-table-wrapper .rec-heading,
.tabs-component-wrapper .recommendations-container .rec-heading {
  margin-top: -0.8rem;
  margin-left: 0.8rem;
}
.tabs-component-wrapper
  .dynamic-component-container
  > .inputClassForEntityTaggingSearch {
  margin-left: 2.4rem;
}
.tabs-component-wrapper .dynamic-component-container .u-icon-text-action {
  margin: 0;
  margin-right: 2.4rem;
  margin-left: 2.4rem;
  margin-bottom: 2.4rem;
}
.tabs-component-wrapper
  .dynamic-component-container
  .u-spacing-mh-s
  > div.u-icon-text-action {
  margin: 0;
}
.tabs-component-wrapper .recommendations-container .header {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 2.4rem;
}
.tabs-component-wrapper .recommendations-container .rec-card-container {
  padding-bottom: 0;
}
.tabs-component-wrapper .recommendations-container .custom_widget_body {
  padding: 0;
}
.tabs-component-wrapper
  .recommendations-container
  .count-class
  > .u-display-flex.u-text-overflow-ellipsis::after {
  content: 'recommendations';
  font-family: 'ProximaNova', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 0.4rem;
  margin-top: 0.5rem;
}
</style>
